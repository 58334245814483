import * as React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet";
import { useLocation } from "@reach/router";

import { useStaticQuery, graphql } from "gatsby"

function Seo({ title, description, image }) {
    const { pathname } = useLocation()
    const { site } = useStaticQuery(query)

    const {
        defaultTitle,
        titleTemplate,
        defaultDescription,
        siteUrl,
        defaultImage,
    } = site.siteMetadata

    const url = `${siteUrl}${pathname}`;
    const socialImage = `${siteUrl}${image || defaultImage}`;

    return (
        <Helmet title={title || defaultTitle} titleTemplate={titleTemplate}>
            <meta name="description" content={description || defaultDescription} />
            <meta property="og:type" content="website" />
            <meta name="og:title" property="og:title" content={title || defaultTitle} />
            <meta name="og:description" property="og:description" content={description || defaultDescription} />
            <meta property="og:site_name" content={title || defaultTitle} />
            <meta property="og:url" content={url} />
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:title" content={title || defaultTitle} />
            <meta name="twitter:description" content={description || defaultDescription} />
            <meta name="twitter:site" content={title || defaultTitle} />
            <meta name="twitter:creator" content={description || defaultDescription} />
            <meta property="og:image" content={socialImage} />
            <meta name="twitter:image" content={socialImage} />
            <link rel="canonical" href={url} />
            <meta name="facebook-domain-verification" content="g6veztur9t5x6mbyikhhqgo4c2hcjv" />
        </Helmet>
    )
}

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        siteUrl: url
        defaultImage: image
      }
    }
  }
`

export default Seo

Seo.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
}

Seo.defaultProps = {
    title: null,
    description: null,
    image: null,
}
