import * as React from "react";
import { Link } from "gatsby"

const Header = ({ siteTitle }) => (
    <header className={`py-2 bg-primary text-center md:text-left text-white`}>
        <div className={`container mx-auto`}>
            <h2 style={{ margin: 0 }} className={`text-white`}>
                <Link to="/">
                    {siteTitle}
                </Link>
            </h2>
        </div>
    </header>
);

export default Header;
