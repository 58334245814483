const Reducer = (state, action) => {
    switch (action.type) {
        case 'SHOW_MODAL':
            window.dataLayer.push( { event: 'configurator-open' } );
            return {
                ...state,
                show_modal: action.payload
            };
        default:
            return state;
    }
};

export default Reducer;
