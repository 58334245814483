import * as React from "react";
import { Link } from "gatsby"

import Header from "./Header";
import Store from "../utils/store";

const Wrapper = ({ children, displayHeader, title }) => {
    return (
        <Store>
            <div className={`wrapper`}>
                {displayHeader && <Header siteTitle={title || 'Elektromobilvergleich'} />}
                <main className={`layout-default`}>
                    {children}
                </main>
                <footer className={`bg-primary text-white py-2`}>
                    <div className="container mx-auto">
                        <div className="flex flex-col md:items-center">
                            <ul className="md:flex mx-auto items-center text-xs">
                                <li className="md:mr-2 lg:py-0"><Link to="/impressum/">Impressum</Link></li>
                                <li className="lg:py-0"><Link to="/datenschutz/">Datenschutz</Link></li>
                            </ul>
                        </div>
                    </div>
                </footer>
            </div>
        </Store>
    )
}

export default Wrapper;
